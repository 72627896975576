import { GlobalDataContext } from 'providers/globalDataProvider'
import React, { useContext, useEffect, useState } from 'react'
import LazyLoad from 'react-lazyload'
import { Helmet } from 'react-helmet'
// data
// import galleryData from 'data/gallery.json'
// css
import './gallery.scss'

const GalleryPage = () => {
  const { TEMPLATE_CONFIG } = useContext(GlobalDataContext)
  const gallery = TEMPLATE_CONFIG?.templateData?.gallery?.page_gallery

  const [wGallery, setWGallery] = useState(gallery?.new_item)
  const [itemBgImage, setBgImage] = useState({})
  const [showBgImage, setShowBgImage] = useState(false)

  const changeBackgroundImage = (options) => {
    const position = typeof options.position === 'undefined' ? 0 : options.position
    let new_WGallery = [...wGallery]

    if (options.showBgImage) {
      const selectedImage = new_WGallery[position]
      new_WGallery.splice(position, 1)
      new_WGallery.unshift(selectedImage)

      setBgImage({
        featured_image: options?.featured_image,
        description: options?.description,
        title: options?.title,
        position: 0
      })
      setWGallery(new_WGallery)
      toggleBgImage()
    } else {
      let featured_image = options?.featured_image,
        title = '',
        description = '',
        new_position = position

      if (position >= itemBgImage.position || position === 'left') {
        // move to left
        const firstImage = new_WGallery[0]
        new_WGallery.splice(0, 1)
        new_WGallery.push(firstImage)
      } else if (position <= itemBgImage.position || position === 'right') {
        // move to rigth
        const lastImage = new_WGallery[new_WGallery.length - 1]
        new_WGallery.splice(new_WGallery.length - 1, 1)
        new_WGallery.unshift(lastImage)
      }

      if (position === 'left' || position === 'right') {
        featured_image = new_WGallery[itemBgImage.position]?.featured_image
        description = new_WGallery[itemBgImage.position]?.description
        title = new_WGallery[itemBgImage.position]?.title
        new_position = itemBgImage.position
      }

      setWGallery(new_WGallery)
      setBgImage({
        featured_image: featured_image,
        title: title,
        description: description,
        position: new_position
      })
    }
  }

  const toggleBgImage = (options) => {
    if (options) {
      if (options.resetGallery) {
        setWGallery(gallery?.new_item)
      }
    }
    setShowBgImage(!showBgImage)
  }

  useEffect(() => {
    setWGallery(gallery?.new_item)
  }, [gallery])

  return (
    <>
      {/* Meta */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Galeria Momentos Lechoncitos - Lechona Lechoncitos - Sencillamente la Mejor - Bucaramanga
        </title>
        <meta
          name="author"
          content="Lechoneria lechoncitos, Sebastian Baez(sebasbaezcode@gmail.com)"
        />
        ,
        <meta
          name="description"
          content="Galeria Momentos Lechoncitos - Lechona Lechoncitos, la mejor lechoneria de bucaramanga. Ganadores del Lechona Master. Sencillamente la Mejor."
        />
        <meta
          name="keywords"
          content="Galeria Momentos Lechoncitos - Lehcona lechoncitos, Lechona bucaramanga, bucaramanga, lechoneria"
        />
        {/* // SOCIAL */}
        {/* // OpenGraph data (Most widely used) */}
        <meta
          property="og:title"
          content="Galeria Momentos Lechoncitos - Lechona Lechoncitos - Sencillamente la Mejor"
        />
        ,
        <meta
          property="og:site_name"
          content="Galeria Momentos Lechoncitos - Lehcona Lechoncitos"
        />
        ,
        <meta property="og:type" content="website" />,
        <meta property="og:url" content="https://lechonalechoncitos.com" />,
        <meta
          property="og:image"
          content="https://lechonalechoncitos.com/public_sb_images/logo-lechoncitos.png"
        />
        ,
        <meta
          property="og:description"
          content="Galeria Momentos Lechoncitos - Lechona Lechoncitos, la mejor lechoneria de bucaramanga. Ganadores del Lechona Master. Sencillamente la Mejor."
        />
        ,{/* Twitter card     */}
        <meta name="twitter:card" content="summary" />,
        <meta name="twitter:site" content="https://lechonalechoncitos.com" />,
        <meta
          name="twitter:title"
          content="Galeria Momentos Lechoncitos - Lechona Lechoncitos - Sencillamente la Mejor"
        />
        ,
        <meta
          name="twitter:description"
          content="Galeria Momentos Lechoncitos - Lechona Lechoncitos, la mejor lechoneria de bucaramanga. Ganadores del Lechona Master. Sencillamente la Mejor."
        />
        ,{/* Your twitter handle, if you have one. */}
        <meta name="twitter:creator" content="@alligatorio" />,
        <meta
          name="twitter:image:src"
          content="https://lechonalechoncitos.com/public_sb_images/logo-lechoncitos.png"
        />
        ,{/* Google / Schema.org markup: */}
        <meta
          itemprop="name"
          content="Galeria Momentos Lechoncitos - Lechona Lechoncitos - Sencillamente la Mejor"
        />
        ,
        <meta
          itemprop="description"
          content="Galeria Momentos Lechoncitos - Lechona Lechoncitos, la mejor lechoneria de bucaramanga. Ganadores del Lechona Master. Sencillamente la Mejor."
        />
        ,
        <meta
          itemprop="image"
          content="https://lechonalechoncitos.com/public_sb_images/logo-lechoncitos.png"
        />
      </Helmet>
      <div className="gallery-container df-padding-2">
        <div className="gallery lc-container">
          <h1 className="lc-default-title gallery-title mt-15">
            {gallery?.title?.top}
            {/* Vivamos juntos <span className="highlighted">los mejores</span> */}
            <br />
            <span className="hashtag">{gallery?.title?.bottom}</span>
          </h1>
          <LazyLoad height={200}>
            {gallery?.new_item ? (
              <div className="items">
                {gallery?.new_item.map((item, index) => (
                  <div
                    onClick={() => {
                      changeBackgroundImage({
                        featured_image: item?.featured_image,
                        title: item?.title,
                        description: item?.desctiption,
                        showBgImage: true,
                        position: index
                      })
                    }}
                    className="item"
                    key={index}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${item?.featured_image})`
                      }}
                      className="item-bg"
                    >
                      <div className="caption">
                        {/* Caption title */}
                        {item.title ? (
                          <h3 className="caption-title">{item?.title}</h3>
                        ) : (
                          <h3 className="caption-title">{item?.desctiption}</h3>
                        )}
                        <p className="see-more">- Ver Más -</p>
                      </div>
                      {/* Text */}
                      <button type="button" className="btn-see-more">
                        Ver más
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </LazyLoad>
        </div>
        {/* Image Full */}
        <div className={`container-image-full ${showBgImage ? 'show-container-image-full' : ''}`}>
          <span
            onClick={() => {
              toggleBgImage({ resetGallery: true })
            }}
            type="button"
            className="material-icons btn-close"
          >
            close
          </span>
          <div style={{ backgroundImage: `url(${itemBgImage?.featured_image})` }} className="image">
            <div className="text">
              <h1 className="title">{itemBgImage?.title}</h1>
              {itemBgImage?.description ? (
                <p className="description">{itemBgImage?.description}</p>
              ) : null}
            </div>

            <div
              onClick={() => {
                changeBackgroundImage({
                  position: 'right'
                })
              }}
              className="material-icons arrow arrow-left"
              title="Anterior"
            >
              arrow_back_ios
            </div>
            <div
              onClick={() => {
                changeBackgroundImage({
                  position: 'left'
                })
              }}
              className="material-icons arrow arrow-right"
              title="Siguiente"
            >
              arrow_forward_ios
            </div>
            <div className="items-thumbnail">
              {wGallery
                ? wGallery.map((item, index) => (
                    <div
                      key={index}
                      style={{ backgroundImage: `url(${item?.featured_image})` }}
                      onClick={() => {
                        changeBackgroundImage({
                          featured_image: item?.featured_image,
                          position: index
                        })
                      }}
                      className="thumbnail"
                    ></div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
      <br />
    </>
  )
}

export default GalleryPage
