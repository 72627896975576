import { GlobalDataContext } from 'providers/globalDataProvider'
import React, { useContext } from 'react'
// css
import './footer.scss'

const Footer = () => {
  const { TEMPLATE_CONFIG } = useContext(GlobalDataContext)
  const companyInfo = TEMPLATE_CONFIG?.templateData?.company_info

  return (
    <footer>
      <div className="lc-container df-padding-3 footer-container">
        <div className="left">
          <div className="logo-container">
            <img
              src={
                companyInfo?.logo ? companyInfo?.logo : require('assets/img/logo_full.svg').default
              }
              alt="Lechoncitos"
            />
          </div>
          <div className="social-networks">
            <a href={companyInfo?.instagram} target="_blank" rel="noopener noreferrer">
              <img src={require('assets/img/social/instagram.svg').default} alt="Instagram" />
            </a>
            <a href={companyInfo?.facebook} target="_blank" rel="noopener noreferrer">
              <img src={require('assets/img/social/facebook.svg').default} alt="Facebook" />
            </a>
            <a
              href={`https://wa.me/57${companyInfo?.whatsapp}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require('assets/img/social/whatsapp.svg').default} alt="Whatsapp" />
            </a>
          </div>
        </div>
        <div className="right">
          <div className="copyright">
            <p>©Copyright {new Date().getFullYear()} Lechoncitos</p>
          </div>

          <div className="development">
            <p>Desarrollado por&nbsp;</p>
            <a
              href="https://sebasbaezcode.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              sebas_baez_code <span className="material-icons">launch</span>
            </a>
          </div>
          <div className="address">
            <div className="item">
              <span className="material-icons">phone</span>
              <p>{companyInfo?.whatsapp}</p>
            </div>
            <div className="item">
              <span className="material-icons">email</span>
              <p>{companyInfo?.email}</p>
            </div>
            <div className="item">
              <span className="material-icons">location_on</span>
              <p>{companyInfo?.address}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
