import React, { useContext, useEffect } from 'react'

import CarrozaTwo from 'components/CarrozaTwo'
import Greetings from 'components/Greetings'
import MainSlider from 'components/MainSlider'
// import Carrousel from 'components/Carrousel'
import PlatesGrid from 'components/Menu/PlatesGrid'
import HotDeals from 'components/HotDeals'
import PlatesTwo from 'components/PlatesTwo'
import CustomerReviews from 'components/CustomerReviews'
import { GlobalDataContext } from 'providers/globalDataProvider'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload'
import { Helmet } from 'react-helmet'
// css
import './home.scss'

// Menu - Plates
// import plates from 'data/menu.json'
// Hot Deals
// import hot_deals from 'data/hot_deals.json'

const HomePage = () => {
  // const dataSlider = [
  //     {
  //         smallTitle: 'Prueba nuestra',
  //         bigTitleC1: 'Lechona',
  //         bigTitleC2: 'Especial',
  //         description: 'Lechona de carne de cerdo y pollo, 250gr, 300gr, 400gr, 500gr.',
  //         image: 'main-slider/especial.jpg',
  //         order: '¡Hola!, Estoy interesado en *Lechona Especial mixta de carne de cerdo con pollo.*'
  //     },
  //     {
  //         smallTitle: 'Prueba Lechona',
  //         bigTitleC1: 'Tradicional',
  //         bigTitleC2: '',
  //         description: 'Lechona Tradicional solo cerdo.',
  //         image: 'main-slider/tradicional.jpg',
  //         order: '¡Hola!, Estoy interesado en *Lechona Tradicional.*'
  //     },
  //     {
  //         smallTitle: 'Prueba Lechona',
  //         bigTitleC1: 'Super',
  //         bigTitleC2: 'Especial',
  //         description: 'Lechona con carne de cerdo, pollo, salchicha ranchera, queso reinoso, huevos de codorniz, (uvas pasas, opcional).',
  //         image: 'main-slider/super-especial.jpg',
  //         order: '¡Hola!, Estoy interesado en *Lechona Super Especial Huevos de codorniz, queso reinoso, salchicha ranchera, carne de cerdo, pollo y uvas pasas (opcional).*'
  //     },
  //     {
  //         smallTitle: 'Nuevas Empanaditas',
  //         bigTitleC1: 'De',
  //         bigTitleC2: 'Lechona',
  //         description: 'Rellenas de lechona mixta con cerdo y pollo. Lléva 6 Mini x sólo $9.900  y 6 Medianas x solo $15.900',
  //         image: 'main-slider/empanadas-2.jpg',
  //         order: '¡Hola!, Estoy interesado en *Empanaditas de Lechona*'
  //     }
  // ];

  const { TEMPLATE_CONFIG } = useContext(GlobalDataContext)
  const dataSlider = TEMPLATE_CONFIG?.templateData?.home_page?.featured_offers
  const hotDealsSlider = TEMPLATE_CONFIG?.templateData?.home_page?.special_offers
  const featuredGallery = TEMPLATE_CONFIG?.templateData?.gallery?.featured_gallery
  const menuContent = TEMPLATE_CONFIG?.templateData?.menu?.pagina
  const menuPlates = TEMPLATE_CONFIG?.templateData?.menu?.plates?.plate

  return (
    <div className="home">
      {/* Meta */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lechona Lechoncitos - Sencillamente la Mejor - Bucaramanga</title>
        <meta
          name="author"
          content="Lechoneria lechoncitos, Sebastian Baez(sebasbaezcode@gmail.com)"
        />
        ,
        <meta
          name="description"
          content="Lechona Lechoncitos, la mejor lechoneria de bucaramanga. Ganadores del Lechona Master. Sencillamente la Mejor."
        />
        <meta
          name="keywords"
          content="Lehcona lechoncitos, Lechona bucaramanga, bucaramanga, lechoneria"
        />
        {/* // SOCIAL */}
        {/* // OpenGraph data (Most widely used) */}
        <meta property="og:title" content="Lechona Lechoncitos - Sencillamente la Mejor" />,
        <meta property="og:site_name" content="Lehcona Lechoncitos" />,
        <meta property="og:type" content="website" />,
        <meta property="og:url" content="https://lechonalechoncitos.com" />,
        <meta
          property="og:image"
          content="https://lechonalechoncitos.com/public_sb_images/logo-lechoncitos.png"
        />
        ,
        <meta
          property="og:description"
          content="Lechona Lechoncitos, la mejor lechoneria de bucaramanga. Ganadores del Lechona Master. Sencillamente la Mejor."
        />
        ,{/* Twitter card     */}
        <meta name="twitter:card" content="summary" />,
        <meta name="twitter:site" content="https://lechonalechoncitos.com" />,
        <meta name="twitter:title" content="Lechona Lechoncitos - Sencillamente la Mejor" />,
        <meta
          name="twitter:description"
          content="Lechona Lechoncitos, la mejor lechoneria de bucaramanga. Ganadores del Lechona Master. Sencillamente la Mejor."
        />
        ,{/* Your twitter handle, if you have one. */}
        <meta name="twitter:creator" content="@alligatorio" />,
        <meta
          name="twitter:image:src"
          content="https://lechonalechoncitos.com/public_sb_images/logo-lechoncitos.png"
        />
        ,{/* Google / Schema.org markup: */}
        <meta itemprop="name" content="Lechona Lechoncitos - Sencillamente la Mejor" />,
        <meta
          itemprop="description"
          content="Lechona Lechoncitos, la mejor lechoneria de bucaramanga. Ganadores del Lechona Master. Sencillamente la Mejor."
        />
        ,
        <meta
          itemprop="image"
          content="https://lechonalechoncitos.com/public_sb_images/logo-lechoncitos.png"
        />
      </Helmet>
      {/* greetings */}

      {/* <Greetings  /> */}


      {/* Slider */}
      <MainSlider dataSlider={dataSlider} isLoading={TEMPLATE_CONFIG.isTemplateLoading} />
      {/* <Carrousel /> */}
      <CarrozaTwo />
      <PlatesTwo plates={menuPlates} />


      {/* Menú */}
      {/* <div className="explore-menu lc-container df-padding-3">
        <h1 className="lc-default-title">
          <span className="highlighted">{menuContent?.title}</span>
          <span className="back">{menuContent?.title}</span>
        </h1>
        <PlatesGrid plates={menuPlates} xs={3} sm={4} lg={6} />

        <div style={{ textAlign: 'center' }}>{menuContent?.info_tag}</div>
        <div className="container-menu-button">
          <Link exact="true" to="/menu" className="default-btn btn-menu">
            {menuContent?.button_test}
          </Link>
        </div>
      </div> */}
      {/* Hot Deals */}
      <div className="hot-deals lc-container df-padding-3">
        <h1 className="lc-default-title">
          Ofertas <span className="highlighted">Especiales</span>
          <span className="back">Ofertas Especiales</span>
        </h1>
        <HotDeals dataSlider={hotDealsSlider} />
      </div>
      {/* Customer Reviews */}
      <div className="customer-reviews lc-container df-padding-3">
        <h1 className="lc-default-title title">
          Opinión <span className="highlighted">de los Clientes</span>
          <span className="back">Opinión de los Clientes</span>
        </h1>
        <CustomerReviews />
      </div>
      {/* Open Gallery */}
      <LazyLoad height={200}>
        <div className="open-gallery">
          <div className="container-imgs">
            {featuredGallery?.featured_gallery_urls ? (
              <div className="images">
                {featuredGallery?.featured_gallery_urls.map((itemUrl, index) => {
                  let orderClass = 'desktop-lg'
                  if (index === 0) orderClass = 'mobile'
                  if (index === 1 || index === 2) orderClass = 'tablet'
                  if (index === 3 || index === 4) orderClass = 'desktop'
                  return <img src={itemUrl} className={`image ${orderClass}`} key={index} alt="Lechoncitos" />
                })}
              </div>
            ) : null}
            <div className="text">
              <p>
                {featuredGallery?.title?.top} <br />
                <span>{featuredGallery?.title?.bottom}</span>
              </p>
            </div>
          </div>
          <div className="container-button">
            <Link exact="true" to="/galeria" className="default-btn btn-open-gallery">
              Ver Galería
            </Link>
          </div>
        </div>
      </LazyLoad>
      <br />
      <br />
      <br />
    </div>
  )
}

export default HomePage
