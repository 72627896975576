import React from 'react'
import './customerReviews.scss'

const CustomerReviews = () => {
    return (
        <div id="customer_reviews">
            <div className="review">
                <div className="photo">
                    <img src={require(`assets/img/customer-review.svg`).default} alt="Review"/>
                </div>
                <div className="text">
                    <p>
                        Esta lechona está espectacular, ya va siendo hora de que hablemos de Lechoncitos.
                        El sabor es increíble, además de que ganaron el <b>Lechona Master</b>. 
                        Recomiendo la lechona especial.
                    </p>
                    <div className="quote">
                        <span>- Jhon Maldonado</span>
                    </div>
                </div>
            </div>
            <div className="review">
                <div className="photo">
                    <img src={require(`assets/img/customer-review.svg`).default} alt="Review"/>
                </div>
                <div className="text">
                    <p>
                        Se me volvió mi restaurante predilecto. <br/> 
                        Porciones grandes. <br/> 
                        Restaurante económico. <br/>
                        Buen ambiente familiar.
                    </p>
                    <div className="quote">
                        <span>- Martín Quintero</span>
                    </div>
                </div>
            </div>
            <div className="review">
                <div className="photo">
                    <img src={require(`assets/img/customer-review.svg`).default} alt="Review"/>
                </div>
                <div className="text">
                    <p>Puedo decir si ningúna duda que, lechoneria Lechoncitos tiene la mejor lechona que he probado en la vida.</p>
                    <div className="quote">
                        <span>- Brayan Uribe</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerReviews
