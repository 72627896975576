import { GlobalDataProvider } from 'providers/globalDataProvider'
import React from 'react'
import Header from 'components/Header'

function App() {
  return (
    <div className="App">
      <GlobalDataProvider>
        <Header />
      </GlobalDataProvider>
    </div>
  )
}

export default App
