import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Plate from 'components/Menu/Plate'
import PlateModal from '../PlateModal/PlateModal'
// css
import './platesGrid.scss'

const PlatesGrid = ({ plates, xs, sm, lg }) => {
  const [filterPlates, setFilterPlates] = useState(plates)
  const [plateToModal, setPlateToModal] = useState({})
  const [showPlateModal, setshowPlateModal] = useState(false)

  const handleFilter = (e) => {
    let width = window.screen.width,
      quantity = 0

    if (xs !== 0 || sm !== 0 || lg !== 0) {
      if (width <= 360 && xs !== 0) {
        quantity = xs
      } else if (width >= 760 && width < 1280 && sm !== 0) {
        quantity = sm
      } else if (width >= 1280 && lg !== 0) {
        quantity = lg
      } else {
        quantity = 0
      }
    }

    setPlates(quantity)
  }

  useEffect(() => {
    handleFilter()
    window.addEventListener('resize', handleFilter)

    return () => {
      window.removeEventListener('resize', handleFilter)
    }
  }, [plates])

  useEffect(() => {
    console.log('GRID ', plates)
  }, [plates])

  const setPlates = (quantity) => {
    if (quantity !== 0) {
      const filteredPlates = plates.slice(0, quantity)
      setFilterPlates(filteredPlates)
    } else {
      setFilterPlates(plates)
    }
  }

  const togglePlateModal = (plate) => {
    console.log(plate)
    setPlateToModal(plate)
    setshowPlateModal(!showPlateModal)
  }

  return (
    <div className="plates-grid">
      <PlateModal
        showModal={showPlateModal}
        plate={plateToModal}
        togglePlateModal={togglePlateModal}
      />
      {filterPlates.length > 0
        ? filterPlates.map((plate, index) => (
            <Plate
              key={index}
              plate={plate}
              onPlateModalChange={(plate) => togglePlateModal(plate)}
            />
          ))
        : null}
    </div>
  )
}

PlatesGrid.propTypes = {
  plates: PropTypes.array,
  xs: PropTypes.number,
  sm: PropTypes.number,
  lg: PropTypes.number
}

PlatesGrid.defaultProps = {
  plates: [
    {
      feauted_image: '',
      title: '',
      description: '',
      price: '',
      text_copy: '',
      url: ''
    }
  ],
  xs: 0,
  sm: 0,
  lg: 0
}

export default PlatesGrid
