export const getTemplateStyles = ({ mainColor1, mainColor2, mainColor3 }) => {
  // .btn-order-now,
  // .btn-order-now:hover,

  const styles = `
      .gallery-container .gallery .gallery-title .hashtag,
      .plate .content .title,
      #lc_header .lc-container-header .center .container-items .items li a:hover,
      .plate:hover .content .description,
      .home .open-gallery .container-imgs .text p span {
        color: ${mainColor2} !important;
      }
      
      footer .footer-container .right .address:after,
      .plate:hover .content,
      .plate .content .bottom .btn-order-desktop .item-wrapper,
      .main-slider .content-slider .right .slider .controls:before,
      .main-slider .content-slider .right .slider .controls,
      .main-slider .content-slider .right .slider .controls .arrows .arrow,
      .main-slider .content-slider .right .slider .slider-indicators .indicator,
      footer .footer-container .right .address,
      .default-btn {
        background-color: ${mainColor1} !important;
      }

      @media (max-width: 1023px) {
        #lc_header .lc-container-header .center .container-items {
          background-color: ${mainColor1} !important;
        }
        #lc_header .lc-container-header .center .container-items .items li a:hover {
          color: ${mainColor2} !important;
        }
      }

      .home .open-gallery .container-imgs .text p {
        color: ${mainColor1} !important;
      }

      .main-color-2,
      footer .footer-container .right .development a,
      .plate .content .bottom .btn-order-desktop .item-wrapper p a,
      .plate .content .bottom .btn-see-details,
      .footer .footer-container .right .development a,
      #hot_deals .hot-dealts-container .right .data .first-title,
      .plate .content .bottom .btn-order-desktop .item-wrapper p,
      .lc-default-title .highlighted,
      .lc-default-title .back {
        color: ${mainColor2} !important;
      }
      
      .btn-order-now:hover {
        background: #FFFFFF !important;
        color: ${mainColor2} !important;
      }

      .main-slider .content-slider .right .slider .controls .arrows .arrow-active,
      #lc_header .lc-container-header .right .btn-order-now:hover,
      .plate .content .bottom .btn-see-details,
      .plate .content .bottom .btn-order-desktop .item-wrapper,
      .plate .content .bottom .btn-order,
      .arrow .arrow-active,
      .btn-order-now:hover {
        border-color: ${mainColor2} !important;
      }

      .main-slider .content-slider .right .slider .slider-indicators .indicator-active,
      .main-slider .content-slider .right .slider .controls .arrows .arrow-active,
      .plate .content .bottom .btn-order,
      .btn-order-now,
      .arrow .arrow-active,
      .default-btn:hover,
      .menu-page .menu-container .container-download-menu .btn-download-menu,
      .indicator .indicator-active {
        background-color: ${mainColor2} !important;
      }

      .#hot_deals .arrow-next {
        background-color: ${mainColor3} !important;
      }
    `

  return styles
}
