import React, { useState, useEffect, useContext } from 'react'
import { BrowserRouter as Router, Route, NavLink, Routes } from 'react-router-dom'
import './header.scss'
import ReactGA from 'react-ga'
// Pages
import { GlobalDataContext } from 'providers/globalDataProvider'
import HomePage from 'components/HomePage'
import GalleryPage from 'components/GalleryPage'
import AboutUsPage from 'components/AboutUsPage'
import MenuPage from 'components/MenuPage'
import Footer from 'components/Footer'
import { exact } from 'prop-types'

const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false)
  const [lastScroll, setLastScroll] = useState()
  const [headerVisibility, setHeaderVisibility] = useState('')
  const { TEMPLATE_CONFIG } = useContext(GlobalDataContext)
  const companyInfo = TEMPLATE_CONFIG?.templateData?.company_info

  useEffect(() => {
    const handleScroll = () => {
      window.scrollY > lastScroll ? setHeaderVisibility('hidden-header') : setHeaderVisibility('')
      setLastScroll(window.scrollY)
    }

    ReactGA.initialize('UA-165742710-1')
    ReactGA.pageview(window.location.pathname + window.location.search)
    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [lastScroll])

  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu)
  }

  return (
    <Router>
      {/* lc => lechoncitos */}
      <header
        id="lc_header"
        className={`lc-header-black ${headerVisibility} ${toggleMenu ? 'no-hidden ' : ''}`}
      >
        <div className="lc-container-header lc-container">
          <div className="left">
            <NavLink to="/">
              {companyInfo?.logo ? (
                <img
                  className="logo"
                  // src={require('assets/img/christmas/testlogo.png')}
                  src={companyInfo.logo}
                  alt="Lechoncitos"
                />
              ) : (
                <img
                  className="logo"
                  src={require('assets/img/logo.svg').default}
                  alt="Lechoncitos"
                  style={{ opacity: '0' }}
                />
              )}
            </NavLink>
          </div>
          <div className="center">
            <div className={`${toggleMenu ? 'show-menu ' : ''}container-items`}>
              <ul className="items">
                <li>
                  <NavLink
                    exact={`${exact}`}
                    onClick={handleToggleMenu}
                    activeclassname="item-active"
                    to="/"
                  >
                    Inicio
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact={`${exact}`}
                    onClick={handleToggleMenu}
                    activeclassname="item-active"
                    to="/menu"
                  >
                    Carta
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact={`${exact}`}
                    onClick={handleToggleMenu}
                    activeclassname="item-active"
                    to="/galeria"
                  >
                    Galería
                  </NavLink>
                </li>
                <li>
                  <a href="/blog" onClick={handleToggleMenu}>
                    Blog
                  </a>
                </li>
                <li>
                  <NavLink
                    exact={`${exact}`}
                    onClick={handleToggleMenu}
                    activeclassname="item-active"
                    to="/quienes-somos"
                  >
                    Lechoncitos
                  </NavLink>
                </li>
              </ul>
            </div>
            {/* <button className="btn-order-now">
                            Ordenar Ahora
                        </button> */}
          </div>
          <div className="right">
            <span onClick={handleToggleMenu} id="icon_menu" className="material-icons">
              menu
            </span>
            <a
              href={
                companyInfo?.header_button?.custom_url !== ''
                  ? companyInfo?.header_button?.custom_url
                  : `https://wa.me/57${companyInfo?.whatsapp}?text=Hola, estoy interesado en...`
              }
              className="btn-order-now"
              target="_blank"
              rel="noopener noreferrer"
            >
              {companyInfo?.header_button?.text}
            </a>
            {/* <button className="btn-order-now">
                                Ordenar
                        </button> */}
          </div>
        </div>
        <div className="bottom">
          .
        </div>
      </header>

      {/* no se que hace esto*/}
      {/* {companyInfo?.whatsapp_logo ? (
        <a
          href={`https://wa.me/57${companyInfo?.whatsapp}?text=Hola, estoy interesado en...`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={companyInfo.whatsapp_logo}
            title="Escribenos al WhatsApp"
            className="global-whats-icon"
            alt="Whatsapp"
          />
        </a>
      ) : null} */}
      <Routes>
        <Route exact={`${exact}`} path="/" element={<HomePage />} />
        <Route exact={`${exact}`} path="/galeria" element={<GalleryPage />} />
        <Route exact={`${exact}`} path="/quienes-somos" element={<AboutUsPage />} />
        <Route exact={`${exact}`} path="/menu" element={<MenuPage />} />
      </Routes>

      <Footer />
    </Router>
  )
}

export default Header
