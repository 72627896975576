import { useContext, useEffect, useState } from 'react'
import './platestwo.scss'
import { array } from 'prop-types'
import { GlobalDataContext } from 'providers/globalDataProvider'
import { NavLink } from 'react-router-dom'

const PlatesTwo = ({ plates }) => {
  // console.log(plates)

  const [platesFiltered, setPlatesFiltered] = useState([])
  const featured = [
    'lechona especial',
    'lechona tradicional',
    'tipico lechoncitos',
    'empanadas de lechona',
    'maduro relleno',
    'especial de la casa',
    'picada criolla'
  ]
  const _clearName = (_text) => {
    if (!_text) return
    _text = _text.toLowerCase()
    _text = _text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    _text = _text.replace(/[^a-z0-9\s]/g, '')
    return _text.trim()
  }

  const _getFeaturedPlates = (_plates) => {
    const features = _plates.filter((plate) => {
      const _plateName = _clearName(plate.title)
      return featured.includes(_plateName)
    })
    if (features.length > 6) {
      const hasEspecial = featured.some(
        (_plate) => _clearName(_plate.title) === 'especial de la casa'
      )

      if (hasEspecial) {
        const indexPicada = features.findIndex(
          (plate) => _clearName(plate.title) === 'picada criolla'
        )
        if (indexPicada !== -1) {
          features.splice(indexPicada, 1)
        }
      } else {
        // features.pop()
      }
    }
    features.sort((a, b) => {
      const nameA = _clearName(a.title)
      const nameB = _clearName(b.title)
      return featured.indexOf(nameA) - featured.indexOf(nameB)
    })
    return features
  }

  useEffect(() => {
    // console.log('JAJAJAJAJAJJAJAJAJAAJJJJJAJAJ', plates)
    // const originals = ['Lechona Especial', 'Lechona Tradicional', 'Típico Lechoncitos', 'Empanadas de Lechona', 'Maduro relleno', 'Especial de la casa', 'Picada criolla']
    if (!plates) return
    const featured = _getFeaturedPlates(plates)
    setPlatesFiltered(featured)
  }, [plates])

  return (
    <div className="main-platestwo">
      <div className="platestwo">
        <div className="top">
          <div className="ribbon">Explorar carta</div>
        </div>
        <div className="plates-grid">
          {platesFiltered?.slice(0, 6).map((plate, index) => {
            return (
              <>
                <Plate key={index} plate={plate} />
              </>
            )
          })}
        </div>
        <div className="bottom">
          <span>En domicilios, 700 pesos extra por el empaque.</span>
          <div className="ver-carta">
            <NavLink to="/menu">Ver Carta</NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}

const Plate = ({ plate }) => {
  // console.log('el plato', plate)

  const [isHovered, setIsHovered] = useState(false)
  const [isHovered2, setIsHovered2] = useState(false)
  return (
    <div className="plate-container">
      <div
        className={`${isHovered2 ? 'gift gift-hover-an' : 'gift'}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div class="gift-top"></div>
        <div class="gift-box">
          <span className="plate-title">{plate?.title}</span>
          <p className="description">{plate?.description}</p>
          <div className="order-now">
            Ordenar ahora
            <PopUp plate_title={plate?.title} />
          </div>
        </div>
      </div>

      <div
        className={`${
          isHovered ? 'christmas-ball-container ball-hover-an' : 'christmas-ball-container'
        }`}
        onMouseEnter={() => setIsHovered2(true)}
        onMouseLeave={() => setIsHovered2(false)}
      >
        <div className={`${isHovered ? 'top-holder jjj' : 'top-holder'}`}></div>
        <div className={`${isHovered ? 'christmas-ball kkk' : 'christmas-ball'}`}>
          <img src={plate?.feauted_image} alt="Christmas Ball" />
        </div>
      </div>
    </div>
  )
}

const PopUp = ({ plate_title }) => {
  const { TEMPLATE_CONFIG } = useContext(GlobalDataContext)
  const companyInfo = TEMPLATE_CONFIG?.templateData?.company_info

  // console.log('yujuu', companyInfo)

  return (
    <div className="main-popup">
      <div className="ver-menu">
        <NavLink to={'/menu'}>
          <a href="">Ver en menú</a>
        </NavLink>
      </div>
      <div className="pedir-ahora">
        <a
          href={`https://wa.me/57${companyInfo?.whatsapp}?text=Hola buen día, me interesaría pedir una porción de ${plate_title}. Me gustaría saber el precio`}
        >
          {/* Pedir ahora */}
          Ordenar ahora
        </a>
      </div>
    </div>
  )
}

export default PlatesTwo
