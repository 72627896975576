import { GlobalDataContext } from 'providers/globalDataProvider'
import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
// css
import './hotDeals.scss'

const HotDeals = ({ dataSlider }) => {
  const { TEMPLATE_CONFIG } = useContext(GlobalDataContext)
  const companyInfo = TEMPLATE_CONFIG?.templateData?.company_info

  const [activeSlide, setActiveSlide] = useState(0)
  const maxSlide = dataSlider.length

  const changeActiveSlide = () => {
    let slide = activeSlide + 1
    if (slide > maxSlide - 1) {
      slide = 0
    }
    setActiveSlide(slide)
  }

  return (
    <div id="hot_deals">
      <span
        onClick={changeActiveSlide}
        className="material-icons arrow-next"
        title="Siguiente"
        style={{
          opacity: dataSlider?.length > 1 ? '1' : '0.2',
          cursor: dataSlider?.length > 1 ? 'pointer' : 'not-allowed'
        }}
      >
        arrow_forward_ios
      </span>
      <TransitionGroup>
        <CSSTransition timeout={1000} key={activeSlide} classNames="hot-deal">
          <div>
            {dataSlider.map((slide, index) => (
              <div
                key={index}
                style={activeSlide !== index ? { display: 'none' } : {}}
                className="hot-dealts-container"
              >
                <div className="left">
                  <div className="container-image">
                    <img src={slide?.featured_image} className="image" alt="" />
                  </div>
                </div>
                <div className="right">
                  <div className="data">
                    <h2 className="first-title">{slide?.title?.top}</h2>
                    <h2 className="second-title">{slide?.title?.bottom}</h2>
                    <p className="description">{slide.description}</p>
                    <div className="container-button">
                      <a
                        className="default-btn"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://wa.me/57${companyInfo?.whatsapp}?text=${
                          slide?.button_config?.url !== ''
                            ? slide?.button_config?.url
                            : slide?.button_config?.text_copy
                        }`}
                      >
                        {slide?.button_config?.text ?? 'Ordenar Ahora'}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  )
}

HotDeals.propTypes = {
  dataSlider: PropTypes.array
}

HotDeals.defaultProps = {
  dataSlider: [
    {
      title: {
        top: 'Oferta Especial',
        bottom: 'Arroz'
      },
      featured_image: 'default-hot-deal.png',
      description: 'Delicioso Combo Lechoncitos con gaseosa personal por sólo $24.000',
      button_config: {
        text: 'Ordenar Ahora',
        text_copy: '¡Hola!, Estoy interesado en un platillo de Lechoncitos 2',
        url: ''
      }
    }
  ]
}

export default HotDeals
