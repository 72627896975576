import { GlobalDataContext } from 'providers/globalDataProvider'
import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import 'atropos/css'
import Atropos from 'atropos/react'
// import Atropos from 'atropos'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
// css
import './mainSlider.scss'
import { motion, AnimatePresence } from 'motion/react'
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

const MainSlider = ({ dataSlider, isLoading }) => {
  const { TEMPLATE_CONFIG } = useContext(GlobalDataContext)
  const companyInfo = TEMPLATE_CONFIG?.templateData?.company_info
  const [activeSlide, setActiveSlide] = useState(0)
  const maxSlide = dataSlider.length

  // const myAtropos = Atropos({
  //   el: '.my-atropos',
  //   activeOffset: 40,
  //   shadowScale: 1.05,
  //   onEnter() {
  //     // console.log("Enter");
  //   },
  //   onLeave() {
  //     // console.log("Leave");
  //   },
  //   onRotate(x, y) {
  //     // console.log("Rotate", x, y);
  //   }
  // })

  const changeActiveSlide = (index) => {
    if (!isNaN(index) && index <= maxSlide - 1 && index >= 0) {
      setActiveSlide(index)
    } else {
      let slide = index === 'prev' ? activeSlide - 1 : activeSlide + 1
      if (slide < 0) {
        slide = maxSlide - 1
      }
      if (slide > maxSlide - 1) {
        slide = 0
      }
      setActiveSlide(slide)
    }
  }

  useEffect(() => {}, [])

  return (
    <div className="main-greetings">
      {/* <div className="snow_wrap">
        <div className="snow"></div>
      </div> */}
      <div class="background-container">
        <div class="landscape"></div>
        <div class="scene">
          <div class="tree tree-1">
            <svg viewBox="0 0 100 150" xmlns="http://www.w3.org/2000/svg">
              <polygon points="50,0 20,60 80,60" fill="#4caf50" />
              <polygon points="50,25 10,75 90,75" fill="#388e3c" />
              <polygon points="50,50 5,100 95,100" fill="#2c6b2f" />
              <rect x="45" y="100" width="10" height="50" fill="#8b4513" />
            </svg>
          </div>
          <div class="gift gift-1"></div>

          <div class="tree tree-2">
            <svg viewBox="0 0 100 150" xmlns="http://www.w3.org/2000/svg">
              <polygon points="50,0 10,60 90,60" fill="#2e7d32" />
              <polygon points="50,25 15,80 85,80" fill="#388e3c" />
              <polygon points="50,50 10,110 90,110" fill="#1b5e20" />
              <rect x="45" y="110" width="10" height="40" fill="#8b4513" />
            </svg>
          </div>
          <div class="gift gift-2"></div>

          <div class="tree tree-3">
            <svg viewBox="0 0 100 150" xmlns="http://www.w3.org/2000/svg">
              <circle cx="50" cy="40" r="30" fill="#8d6e63" />
              <circle cx="50" cy="20" r="25" fill="#6d4c41" />
              <circle cx="50" cy="10" r="20" fill="#4e342e" />
              <rect x="45" y="70" width="10" height="40" fill="#3e2723" />
            </svg>
          </div>
          <div class="gift gift-3"></div>

          <div class="tree tree-4">
            <svg viewBox="0 0 100 150" xmlns="http://www.w3.org/2000/svg">
              <polygon points="50,0 15,55 85,55" fill="#2b9e56" />
              <polygon points="50,25 10,80 90,80" fill="#33691e" />
              <polygon points="50,50 20,100 80,100" fill="#4caf50" />
              <rect x="45" y="100" width="10" height="50" fill="#6d4f2f" />
            </svg>
          </div>
          <div class="gift gift-4"></div>

          <div class="tree tree-5">
            <svg viewBox="0 0 100 150" xmlns="http://www.w3.org/2000/svg">
              <circle cx="50" cy="35" r="35" fill="#8d6e63" />
              <circle cx="50" cy="10" r="30" fill="#6d4c41" />
              <rect x="45" y="70" width="10" height="50" fill="#3e2723" />
            </svg>
          </div>
          <div class="gift gift-5"></div>
        </div>

        <div class="tree tree-6">
          <svg viewBox="0 0 100 150" xmlns="http://www.w3.org/2000/svg">
            <polygon points="50,0 20,60 80,60" fill="#4caf50" />
            <polygon points="50,25 10,75 90,75" fill="#388e3c" />
            <polygon points="50,50 5,100 95,100" fill="#2c6b2f" />
            <rect x="45" y="100" width="10" height="50" fill="#8b4513" />
          </svg>
        </div>
      </div>

      {dataSlider.map((slide, index) => (
        <div
          className="greetings-content"
          key={index}
          style={activeSlide !== index ? { display: 'none' } : {}}
        >
          {/* Left */}
          <div className="leftside">
            {/* <img src={require('assets/img/christmas/svgs/adorno.svg').default} alt="" className="furniture" /> */}
            <AnimatePresence>
              <motion.div
                className="dish-name"
                key={activeSlide}
                initial={{ clipPath: 'inset(0% 100% 0% 0%)', top: '0', left: '0%', opacity: '1%' }} // Initially hidden on the left
                animate={{
                  clipPath: 'inset(0% 0% 0% 0%)',
                  opacity: 1,
                  transition: { duration: 1 }
                }}
                exit={{
                  clipPath: 'inset(0% 100% 0% 0%)',
                  opacity: 0,
                  transition: { duration: 0.5 },
                  position: 'absolute'
                }}
              >
                {slide?.title?.top}
              </motion.div>
            </AnimatePresence>
            <AnimatePresence>
              <motion.div
                className="hot-title"
                key={activeSlide}
                initial={{
                  clipPath: 'inset(0% 100% 0% 0%)',
                  top: '30%',
                  left: '0%',
                  opacity: '1%'
                }} // Initially hidden on the left
                animate={{
                  clipPath: 'inset(0% 0% 0% 0%)',
                  opacity: '100%',
                  transition: { duration: 0.7 }
                }}
                exit={{
                  clipPath: 'inset(0% 100% 0% 0%)',
                  opacity: '1%',
                  transition: { duration: 0.4 },
                  position: 'absolute'
                }}
              >
                {slide?.title?.bottom}
              </motion.div>
            </AnimatePresence>
            <AnimatePresence>
              <motion.div
                className="description"
                key={activeSlide}
                initial={{
                  clipPath: 'inset(0% 100% 0% 0%)',
                  bottom: '30%',
                  left: '0%',
                  opacity: '1%'
                }} // Initially hidden on the left
                animate={{
                  clipPath: 'inset(0% 0% 0% 0%)',
                  opacity: '100%',
                  transition: { duration: 0.7 }
                }}
                exit={{
                  clipPath: 'inset(0% 100% 0% 0%)',
                  opacity: '1%',
                  transition: { duration: 0.3 },
                  position: 'absolute'
                }}
              >
                {slide.description}
              </motion.div>
            </AnimatePresence>
            <div className="order-button">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://wa.me/57${companyInfo?.whatsapp}?text=${
                  slide?.button_config?.url !== ''
                    ? slide?.button_config?.url
                    : slide?.button_config?.text_copy
                }`}
              >
                Ordernar Ahora
              </a>

              <img src={require('assets/img/christmas/regalos.png')} alt="" className="gifts" />
            </div>

            <div className="socialmedia-container">
              {companyInfo?.instagram ? (
                <a
                  href={companyInfo.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Instagram"
                >
                  <img
                    src={require('assets/img/social/instagram.svg').default}
                    className="social-icon"
                    alt="Instagram"
                  />
                </a>
              ) : null}

              {companyInfo?.facebook ? (
                <a
                  href={companyInfo.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Facebook"
                >
                  <img
                    src={require('assets/img/social/facebook.svg').default}
                    className="social-icon"
                    alt="Facebook"
                  />
                </a>
              ) : null}

              {companyInfo?.whatsapp ? (
                <a
                  href={`https://wa.me/57${companyInfo.whatsapp}?text=Hola, estoy interesado en...`}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Whatsapp"
                >
                  <img
                    src={require('assets/img/social/whatsapp.svg').default}
                    className="social-icon"
                    alt="Maps"
                  />
                </a>
              ) : null}
            </div>
          </div>

          <div className="rightside">
            {/* svgs*/}

            <img
              src={require('assets/img/christmas/svgs/caramelo.svg').default}
              alt=""
              className="candy"
            />
            <img
              src={require('assets/img/christmas/svgs/campanas.svg').default}
              alt=""
              className="bells"
            />
            <img src={require('assets/img/christmas/ball1.png')} alt="" className="ball1" />
            <img src={require('assets/img/christmas/ball2.png')} alt="" className="ball2" />
            <img src={require('assets/img/christmas/ball3.png')} alt="" className="ball3" />
            <AnimatePresence>
              <motion.div
                className="christmas-ball-container"
                key={activeSlide}
                initial={{ opacity: 0, scale: 0.9, rotate: 10 }} 
                animate={{
                  opacity: 1, 
                  scale: 1, 
                  rotate: 0, 
                  transition: { duration: 0.5 } 
                }}
              >
                <div class="top-holder">
                  <div class="string"></div>
                </div>
                <div class="christmas-ball">
                  <img src={slide?.featured_image} alt="Christmas Ball" />
                </div>
              </motion.div>
            </AnimatePresence>
            <div className="slider-btns">
              <div onClick={() => changeActiveSlide('prev')} className="previous" title="Anterior">
                {/* <img src={require('../../assets/img/left-arrow.png')} alt="" /> */}
                <IoIosArrowBack />
              </div>
              <div onClick={() => changeActiveSlide('next')} className="next" title="Siguiente">
                {/* <img src={require('../../assets/img/right-arrow.png')} alt="" /> */}
                <IoIosArrowForward /> 
              </div>
            </div>
          </div>
          <div className="slider-indicators">
            {dataSlider.map((slide, index) => (
              <span
                className={`indicator ${activeSlide === index ? 'indicator-active' : ''}`}
                key={index}
                onClick={() => changeActiveSlide(index)}
              ></span>
            ))}
          </div>
        </div>
      ))}
    </div>
  )

  return (
    <div className={`main-slider ${isLoading ? 'main-slider-loading' : ''} df-padding-2`}>
      {dataSlider.map((slide, index) => (
        <div
          key={index}
          className="lc-container content-slider"
          style={activeSlide !== index ? { display: 'none' } : {}}
        >
          {/* Left */}
          <div className="left">
            {/* Data */}
            <div className="data">
              <TransitionGroup>
                <CSSTransition timeout={1000} classNames="text" key={activeSlide}>
                  <div className="text">
                    <h1 className="small-title">{slide?.title?.top}</h1>
                    <h1 className="big-title">
                      <span className="main-color-2">{slide?.title?.bottom}</span>
                    </h1>
                    <p className="description">{slide.description}</p>
                  </div>
                </CSSTransition>
              </TransitionGroup>
              <TransitionGroup>
                <CSSTransition timeout={1000} classNames="buttons" key={activeSlide}>
                  <div className="buttons">
                    <a
                      className="default-btn"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://wa.me/57${companyInfo?.whatsapp}?text=${
                        slide?.button_config?.url !== ''
                          ? slide?.button_config?.url
                          : slide?.button_config?.text_copy
                      }`}
                    >
                      {slide?.button_config?.text ?? 'Ordenar Ahora'}
                    </a>
                  </div>
                </CSSTransition>
              </TransitionGroup>
            </div>
            {/* Social Networks */}
            <div className="social-networks">
              {companyInfo?.instagram ? (
                <a
                  href={companyInfo.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Instagram"
                >
                  <img
                    src={require('assets/img/social/instagram.svg').default}
                    className="social-icon"
                    alt="Instagram"
                  />
                </a>
              ) : null}

              {companyInfo?.facebook ? (
                <a
                  href={companyInfo.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Facebook"
                >
                  <img
                    src={require('assets/img/social/facebook.svg').default}
                    className="social-icon"
                    alt="Facebook"
                  />
                </a>
              ) : null}

              {companyInfo?.whatsapp ? (
                <a
                  href={`https://wa.me/57${companyInfo.whatsapp}?text=Hola, estoy interesado en...`}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Whatsapp"
                >
                  <img
                    src={require('assets/img/social/whatsapp.svg').default}
                    className="social-icon"
                    alt="Maps"
                  />
                </a>
              ) : null}
            </div>
          </div>
          {/* Right */}
          <div className="right">
            <div className="slider">
              <TransitionGroup>
                <CSSTransition timeout={1000} classNames="image" key={activeSlide}>
                  {/* <div className="container-img-slide" style={{ backgroundImage: `url(${require(`assets/img/${slide.image}`).default})` }}  >
                                            <img src={require(`assets/img/${slide.image}`).default} className="img-slide" alt="Imagen"/>
                                        </div> */}
                  <div
                    className="container-img-slide"
                    style={{
                      // backgroundImage: `url(${require(`assets/img/${slide.image}`).default})`
                      backgroundImage: `url(${slide?.featured_image})`
                    }}
                  >
                    {/* <img src={require(`assets/img/${slide.image}`).default} className="img-slide" alt="Imagen"/> */}
                  </div>
                </CSSTransition>
              </TransitionGroup>
              <div className="controls">
                <div className="lc-placeholder">{/* // */}</div>
                <div className="arrows">
                  <div onClick={() => changeActiveSlide('prev')} className="arrow" title="Anterior">
                    <span className="material-icons arrow-left">arrow_back_ios</span>
                  </div>
                  <div
                    onClick={() => changeActiveSlide('next')}
                    className="arrow arrow-active"
                    title="Siguiente"
                  >
                    <span className="material-icons">arrow_forward_ios</span>
                  </div>
                </div>
              </div>
              <div className="slider-indicators">
                {dataSlider.map((slide, index) => (
                  <span
                    className={`indicator ${activeSlide === index ? 'indicator-active' : ''}`}
                    key={index}
                    onClick={() => changeActiveSlide(index)}
                  ></span>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
  return (
    <div>
      <Atropos className="atropos-banner" highlight={false} onEnter={() => console.log('enter')}>
        <img
          className="atropos-banner-spacer"
          src="https://raw.githubusercontent.com/nolimits4web/atropos/master/playground/react/i/atropos-bg.svg"
          alt=""
        />
        <img
          data-atropos-offset="-4.5"
          src="https://raw.githubusercontent.com/nolimits4web/atropos/master/playground/react/i/atropos-bg.svg"
          alt=""
        />
        <img
          data-atropos-offset="-2.5"
          src="https://raw.githubusercontent.com/nolimits4web/atropos/master/playground/react/i/atropos-mountains.svg"
          alt=""
        />
        <img
          data-atropos-offset="0"
          src="https://raw.githubusercontent.com/nolimits4web/atropos/master/playground/react/i/atropos-forest-back.svg"
          alt=""
        />
        <img
          data-atropos-offset="2"
          src="https://raw.githubusercontent.com/nolimits4web/atropos/master/playground/react/i/atropos-forest-mid.svg"
          alt=""
        />
        <img
          data-atropos-offset="4"
          src="https://raw.githubusercontent.com/nolimits4web/atropos/master/playground/react/i/atropos-forest-front.svg"
          alt=""
        />
        <img
          data-atropos-offset="5"
          src="https://raw.githubusercontent.com/nolimits4web/atropos/master/playground/react/i/atropos-logo-en.svg"
          alt=""
        />
      </Atropos>
    </div>
  )
}

// {/* <div className="App">
// <ReactFullpage
//   debug
//   // afterLoad={afterLoad}
//   anchors={['firstPage', 'secondPage', 'thirdPage', 'fourthPage']}
//   menu="#menu"
//   scrollOverflow={true}
//   scrolloverflowmacstyle={false}
//   responsiveWidth={800}
//   // scrollBar={true}
//   // autoScrolling={false}
//   // licenseKey="xxxxxxxxxxxxxxxxxxxxxxxxx"
//   render={() => (
//     <ReactFullpage.Wrapper>
//       <div className="section" id="section1">
//         <h1>Animations</h1>
//       </div>
//       <div className="section" id="section2">
//         <div className="slide" id="slide2-1">
//           <div className="intro">
//             <h1>Section 2</h1>
//             <p>Animation triggered by fullPage callbacks.</p>
//           </div>
//         </div>
//         <div className="slide">
//           <h1>Slide 2.2</h1>
//         </div>
//         <div className="slide">
//           <h1>Slide 2.3</h1>
//         </div>
//       </div>
//       {/* < */}
//       <div className="section">
// <PlatesGrid plates={menuPlates} xs={3} sm={4} lg={6} />
//       </div>
//       {/* <div className="section" id="section3">
//       <div className="intro">
//         <h1>Section 3</h1>
//         <p>Animation triggered by CSS state classes</p>
//       </div>
//     </div> */}
//     </ReactFullpage.Wrapper>
//   )}
// />
// </div> */}

MainSlider.propTypes = {
  dataSlider: PropTypes.array
}

// C1 => Color 1, C2 => Color 2
MainSlider.defaultProps = {
  // dataSlider: [
  //     {
  //         smallTitle: 'Try Our New',
  //         bigTitleC1: 'Cheezy',
  //         bigTitleC2: 'Burger',
  //         description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt ab sed unde?',
  //         image: 'main-slider/default-image.png',
  //         order: '¡Hola!, Estoy interesado en un platillo de Lechoncitos.'
  //     },
  //     {
  //         smallTitle: 'Try Our New 2',
  //         bigTitleC1: 'Meat',
  //         bigTitleC2: 'Creep',
  //         description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt ab sed unde?',
  //         image: 'main-slider/default-image.png',
  //         order: '¡Hola!, Estoy interesado en un platillo de Lechoncitos 2'
  //     }
  // ]
  dataSlider: [
    {
      title: {
        top: '',
        bottom: ''
      },
      featured_image: '',
      description: '',
      button_config: {
        text: '',
        text_copy: '',
        url: ''
      }
    }
  ]
}

export default MainSlider
