// WordPress API
// https://lechonalechoncitos.com/blog/wp-json/wp/v2/pages?slug=lechoncitos-editor-de-contenido
const siteUrl = 'https://lechonalechoncitos.com/blog/'
const apiUrl = `${siteUrl}/wp-json/wp/v2/pages?slug=lechoncitos-editor-de-contenido`

// Función para obtener los datos de la página de configuración
export const fetchConfiguracion = async () => {
  try {
    const dataReq = await fetch(apiUrl)
    if (!dataReq.ok) {
      throw new Error('No se pudieron obtener los datos de configuración')
    }
    const dataRes = await dataReq.json()
    return dataRes
  } catch (error) {
    console.error('Error al obtener la configuración:', error)
    throw error
  }
}
