import React from 'react'
import parse from 'html-react-parser';
import PropTypes from 'prop-types'
// css
import './plateModal.scss'

const PlateModal = ({ plate, showModal, togglePlateModal }) => {

    const handleTogglePlateModal = () => {
        togglePlateModal(plate)
    }

    return (
        <div className={`plate-modal ${showModal ? 'show-plate-modal' : ''}`}>
            <div className="plate-content">
                <button className="btn-close" onClick={handleTogglePlateModal}>Cerrar</button>
                <h3 className="title">{ plate.title }</h3>
                <p className="description">
                    { 
                        plate.extraDescription ?
                            parse(plate.extraDescription)
                        : ''
                    }
                </p>
            </div>
        </div>
    )
}


PlateModal.propTypes = {
    plate: PropTypes.object
}

PlateModal.defaultProps = {
    plate: {
        title: '',
        extraDescription: '',
    }
}

export default PlateModal