import { GlobalDataContext } from 'providers/globalDataProvider'
import React, { useState, useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet'
// css
import './about-us.scss'

const AboutUsPage = () => {
  const { TEMPLATE_CONFIG } = useContext(GlobalDataContext)
  //   debugger
  const aboutUsContent = TEMPLATE_CONFIG?.templateData?.about_us
  const additional_content = aboutUsContent?.additional_content
  const title = aboutUsContent?.content?.title
  const hide_section = aboutUsContent?.content?.hide_section
  const featured_text = aboutUsContent?.content?.featured_text

  //   const { content, additional_content } = aboutUsContent
  //   const { hide_section, featured_test, title } = content
  const [animationClass, setAnimationClass] = useState('')
  useEffect(() => {
    setAnimationClass('item-animation')
    console.log('ABOUT US', featured_text)
  }, [featured_text])

  return (
    <>
      {/* Meta */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sobre Nosotros - Lechona Lechoncitos - Sencillamente la Mejor - Bucaramanga</title>
        <meta
          name="author"
          content="Lechoneria lechoncitos, Sebastian Baez(sebasbaezcode@gmail.com)"
        />
        ,
        <meta
          name="description"
          content="Sobre Nosotros - Lechona Lechoncitos, la mejor lechoneria de bucaramanga. Ganadores del Lechona Master. Sencillamente la Mejor."
        />
        <meta
          name="keywords"
          content="Sobre Nosotros - Lehcona lechoncitos, Lechona bucaramanga, bucaramanga, lechoneria"
        />
        {/* // SOCIAL */}
        {/* // OpenGraph data (Most widely used) */}
        <meta property="og:title" content="Lechona Lechoncitos - Sencillamente la Mejor" />,
        <meta property="og:site_name" content="Sobre Nosotros - Lehcona Lechoncitos" />,
        <meta property="og:type" content="website" />,
        <meta property="og:url" content="https://lechonalechoncitos.com" />,
        <meta
          property="og:image"
          content="https://lechonalechoncitos.com/public_sb_images/logo-lechoncitos.png"
        />
        ,
        <meta
          property="og:description"
          content="Sobre Nosotros - Lechona Lechoncitos, la mejor lechoneria de bucaramanga. Ganadores del Lechona Master. Sencillamente la Mejor."
        />
        ,{/* Twitter card     */}
        <meta name="twitter:card" content="summary" />,
        <meta name="twitter:site" content="https://lechonalechoncitos.com" />,
        <meta name="twitter:title" content="Lechona Lechoncitos - Sencillamente la Mejor" />,
        <meta
          name="twitter:description"
          content="Sobre Nosotros - Lechona Lechoncitos, la mejor lechoneria de bucaramanga. Ganadores del Lechona Master. Sencillamente la Mejor."
        />
        ,{/* Your twitter handle, if you have one. */}
        <meta name="twitter:creator" content="@alligatorio" />,
        <meta
          name="twitter:image:src"
          content="https://lechonalechoncitos.com/public_sb_images/logo-lechoncitos.png"
        />
        ,{/* Google / Schema.org markup: */}
        <meta itemprop="name" content="Lechona Lechoncitos - Sencillamente la Mejor" />,
        <meta
          itemprop="description"
          content="Sobre Nosotros - Lechona Lechoncitos, la mejor lechoneria de bucaramanga. Ganadores del Lechona Master. Sencillamente la Mejor."
        />
        ,
        <meta
          itemprop="image"
          content="https://lechonalechoncitos.com/public_sb_images/logo-lechoncitos.png"
        />
      </Helmet>
      <div className="about-us-page df-padding-2">
        <div className="about-us-container lc-container">
          <h1 className="lc-default-title mt-15">
            {title}
            <span className="back">{title}</span>
          </h1>
          {hide_section == 'false' && featured_text ? (
            <>
              <div className="text-container">
                {featured_text.map((item) => (
                  <div
                    className={`item ${animationClass}`}
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                  ></div>
                ))}
              </div>
            </>
          ) : null}
          {/*  */}
          {additional_content ? (
            <div
              className="text-container text-container-additional-content"
              dangerouslySetInnerHTML={{ __html: additional_content }}
            ></div>
          ) : null}
        </div>
      </div>
      <br />
      <br />
    </>
  )
}

export default AboutUsPage
