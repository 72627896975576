import { GlobalDataContext } from 'providers/globalDataProvider'
import React, { useContext, useEffect } from 'react'
import PlatesGrid from 'components/Menu/PlatesGrid'
import { Helmet } from 'react-helmet'
// css
import './menu.scss'
// Menu - Plates
import plates from 'data/menu.json'

const MenuPage = () => {
  const { TEMPLATE_CONFIG } = useContext(GlobalDataContext)
  const menuContent = TEMPLATE_CONFIG?.templateData?.menu?.pagina
  const menuPlates = TEMPLATE_CONFIG?.templateData?.menu?.plates?.plate

  useEffect(() => {
    console.log(menuContent?.additional_info)
  }, [menuContent])

  return (
    <>
      {/* Meta */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lechona Lechoncitos - Sencillamente la Mejor - Bucaramanga</title>
        <meta
          name="author"
          content="Lechoneria lechoncitos, Sebastian Baez(sebasbaezcode@gmail.com)"
        />
        ,
        <meta
          name="description"
          content="Menú - Disfruta de los mejores Platos Típicos - Lechona Lechoncitos, la mejor lechoneria de bucaramanga. Ganadores del Lechona Master. Sencillamente la Mejor."
        />
        <meta
          name="keywords"
          content="Lehcona lechoncitos, Lechona bucaramanga, bucaramanga, lechoneria"
        />
        {/* // SOCIAL */}
        {/* // OpenGraph data (Most widely used) */}
        <meta property="og:title" content="Lechona Lechoncitos - Sencillamente la Mejor" />,
        <meta property="og:site_name" content="Lehcona Lechoncitos" />,
        <meta property="og:type" content="website" />,
        <meta property="og:url" content="https://lechonalechoncitos.com" />,
        <meta
          property="og:image"
          content="https://lechonalechoncitos.com/public_sb_images/logo-lechoncitos.png"
        />
        ,
        <meta
          property="og:description"
          content="Menú - Disfruta de los mejores Platos Típicos - Lechona Lechoncitos, la mejor lechoneria de bucaramanga. Ganadores del Lechona Master. Sencillamente la Mejor."
        />
        ,{/* Twitter card     */}
        <meta name="twitter:card" content="summary" />,
        <meta name="twitter:site" content="https://lechonalechoncitos.com" />,
        <meta name="twitter:title" content="Lechona Lechoncitos - Sencillamente la Mejor" />,
        <meta
          name="twitter:description"
          content="Menú - Disfruta de los mejores Platos Típicos - Lechona Lechoncitos, la mejor lechoneria de bucaramanga. Ganadores del Lechona Master. Sencillamente la Mejor."
        />
        ,{/* Your twitter handle, if you have one. */}
        <meta name="twitter:creator" content="@alligatorio" />,
        <meta
          name="twitter:image:src"
          content="https://lechonalechoncitos.com/public_sb_images/logo-lechoncitos.png"
        />
        ,{/* Google / Schema.org markup: */}
        <meta itemprop="name" content="Lechona Lechoncitos - Sencillamente la Mejor" />,
        <meta
          itemprop="description"
          content="Menú - Disfruta de los mejores Platos Típicos - Lechona Lechoncitos, la mejor lechoneria de bucaramanga. Ganadores del Lechona Master. Sencillamente la Mejor."
        />
        ,
        <meta
          itemprop="image"
          content="https://lechonalechoncitos.com/public_sb_images/logo-lechoncitos.png"
        />
      </Helmet>
      <div className="menu-page df-padding-2">
        <div className="menu-container lc-container">
          <h1 className="lc-default-title mt-15">
            {menuContent?.title}
            <span className="back">{menuContent?.title}</span>
          </h1>
          <div className="container-download-menu">
            <a
              href={menuContent?.additional_info?.menu_url}
              rel="noreferrer"
              title="Descargar Carta Completa"
              target="_blank"
            >
              <p className="btn-download-menu">
                {menuContent?.additional_info?.button_text}
                <span className="material-icons">arrow_downward</span>
              </p>
            </a>
          </div>

          <div style={{ textAlign: 'center', marginBottom: '35px' }}>{menuContent?.info_tag}</div>
          <PlatesGrid plates={menuPlates} />
        </div>
      </div>
      <br />
      <br />
    </>
  )
}

export default MenuPage
