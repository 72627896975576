import { GlobalDataContext } from 'providers/globalDataProvider'
import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
// css
import './plate.scss'

const Plate = ({ plate, onPlateModalChange }) => {
  const { TEMPLATE_CONFIG } = useContext(GlobalDataContext)
  const menuContent = TEMPLATE_CONFIG?.templateData?.menu?.pagina
  const companyInfo = TEMPLATE_CONFIG?.templateData?.company_info
  const { feauted_image, title, text_copy, url, description, price } = plate
  const myCustomEvent = () => {
    // console.log('myCustomEvent')
    // onPlateModalChange(plate)
    // <a
    // href='/carta_lechoncitos.pdf'
    window.location.href = menuContent?.additional_info?.menu_url
  }

  return (
    <div className="plate">
      <div
        className="container-image"
        style={{
          backgroundImage: `url(${
            feauted_image && feauted_image !== ''
              ? feauted_image
              : menuContent?.additional_info?.default_plate_img
          }})`
        }}
      >
        {/* <img src={require(`assets/img/menu/${image}`).default} className="image" alt={title}/> */}
      </div>
      <div className="content">
        <h1 className="title">{title}</h1>
        <p className="description">{description}</p>

        <div className="bottom">
          {/* {
                    plate.extraDescription ?
                    <button type="button" className="btn-see-details" onClick={myCustomEvent}>
                        Ver detalles
                    </button>
                    : ''
                } */}
          <button type="button" className="btn-see-details" onClick={myCustomEvent}>
            Ver detalles
          </button>

          <a
            href={
              url && url !== '' ? url : `https://wa.me/57${companyInfo?.whatsapp}?text=${text_copy}`
            }
            target="_blank"
            rel="noopener noreferrer"
            className="btn-order"
          >
            Ordenar Ahora
          </a>

          <button type="button" className="btn-order btn-order-desktop">
            Ordenar Ahora
            <div className="item-wrapper">
              <p>
                <a href="/carta_lechoncitos.pdf" title="Descargar Carta Completa" target="_blank">
                  Ver en el menú
                </a>
              </p>
              <p>
                <a
                  href={
                    url && url !== ''
                      ? url
                      : `https://wa.me/57${companyInfo?.whatsapp}?text=${text_copy}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ordenar ahora
                </a>
              </p>
            </div>
          </button>
          {/* <h5 className="price">$ {price}</h5> */}
          <h5 className="price">&nbsp;</h5>
        </div>
      </div>
    </div>
  )
}

Plate.propTypes = {
  plate: PropTypes.object
}

Plate.defaultProps = {
  plate: {
    feauted_image: '',
    title: '',
    description: '',
    price: '',
    text_copy: '',
    url: ''
  }
}

export default Plate
