import React, { useContext, useEffect, useRef, useState } from 'react'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import './carrozatwo.scss'
import { GlobalDataContext } from 'providers/globalDataProvider'
import { NavLink } from 'react-router-dom'
import { IoIosArrowForward } from 'react-icons/io'
import { IoIosArrowBack } from 'react-icons/io'

const CarrozaTwo = () => {
  const images = [
    require('../../assets/img/carrousel/1.jpg'),
    require('../../assets/img/carrousel/2.jpg'),
    require('../../assets/img/carrousel/3.jpg'),
    require('../../assets/img/carrousel/4.jpg'),
    require('../../assets/img/carrousel/5.jpg'),
    require('../../assets/img/carrousel/6.jpg'),
    require('../../assets/img/carrousel/7.jpg'),
    require('../../assets/img/carrousel/8.jpg')
  ]

  const allPlatosNanes = [
    'Pollo Relleno',
    'Pernil de Cerdo Horneado',
    'Pechuga Rellena con Salsa de Ciruela',
    'Ensalada Tropical',
    'Capón Relleno',
    'Tamales Santandereanos y Tolimenses',
    'Cojín de Lechona',
    'Ensalada Especial de Papa y Verduras'
  ]

  //   const duplicatedImages = [...images, ...images.slice(0, 2)];
  //   const totalSlides = duplicatedImages.length;
  const autoPlayRef = useRef(null)
  const [isHovered, setIsHovered] = useState(false)

  // Para el autoscroll infinito
  
  // useEffect(() => {
  //   if (!isHovered) {
  //     autoPlayRef.current = setInterval(() => {
  //       const nextButton = document.querySelector('.btn-next')
  //       if (nextButton) nextButton.click()
  //     }, 3000)
  //   } else {
  //     clearInterval(autoPlayRef.current)
  //   }

  //   return () => clearInterval(autoPlayRef.current)
  // }, [isHovered])

  const handleMouseEnter = () => setIsHovered(true)
  const handleMouseLeave = () => setIsHovered(false)

  const [slidesToShow, setSlidesToShow] = useState(3); // 3 slides default
  
  const updateSlidesToShow = () => {
    const width = window.innerWidth;
    if (width < 564) {
      setSlidesToShow(1); 
    } else if (width < 824) {
      setSlidesToShow(2); 
    } else {
      setSlidesToShow(3); 
    }
  };

  useEffect(() => {
    updateSlidesToShow(); 
    window.addEventListener('resize', updateSlidesToShow);
    return () => window.removeEventListener('resize', updateSlidesToShow);
  }, []);

  return (
    <div className="carroza-two-container">
      <div className="top-end-content">.</div>
      <div className="carroza-two">
        <div className="carroza-title">
          Celebra con el Auténtico Sabor de nuestros Platillos Navideños
        </div>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={8}
          infinite={true}
          visibleSlides={slidesToShow}
          childrenPaused={true}
          pauseOnHover={true}
          step={1}
        >
          <div
            className="carroza-gallery"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Slider>
              {images.map((img, index) => (
                <Slide index={index} key={index}>
                  <Platito platoName={allPlatosNanes[index]} imgSrc={img} key={index} />
                </Slide>
              ))}
            </Slider>
          </div>
          <div className="slider-buttons">
            <ButtonBack className="btn-back">
              <IoIosArrowBack />
            </ButtonBack>
            <ButtonNext className="btn-next">
              <IoIosArrowForward />
            </ButtonNext>
          </div>
        </CarouselProvider>
      </div>
      <div className="bottom-end-content">.</div>
    </div>
  )
}

const Platito = ({ platoName, imgSrc }) => {
  const { TEMPLATE_CONFIG } = useContext(GlobalDataContext)
  const companyInfo = TEMPLATE_CONFIG?.templateData?.company_info

  const [isHover, setIsHover] = useState(false)

  return (
    <div
      className="platito-container"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div
        className="the-pop-up"
        style={{ opacity: `${isHover ? '90%' : '0%'}`, transform: 'scale(1.2)' }}
      >
        {/* <NavLink to="/menu">
            <a
              style={{ pointerEvents: `${isHover ? 'all' : 'none'}` }}
              className="refs"
            >
              Ver en el menú
            </a>
          </NavLink> */}
        <a
          style={{ pointerEvents: `${isHover ? 'all' : 'none'}` }}
          className="refs"
          target="_blank"
          without rel="noreferrer"
          href={`https://wa.me/57${companyInfo?.whatsapp}?text=NAVIDAD WEB: Hola buen día, me interesaría pedir una porción de ${platoName}. Me gustaría saber el precio`}
          // onClick={() => (window.location.href = this.href)}
        >
          Ordenar ahora
        </a>
      </div>
      <img src={imgSrc} />
      <div className="platito-name">{platoName}</div>
    </div>
  )
}

export default CarrozaTwo
