import { fetchConfiguracion } from 'utils/wordpress-data-fetch.js'
import { getTemplateStyles } from 'utils/get-custom-style'

import React, { createContext, useState, useEffect } from 'react'

// Crear el contexto
const GlobalDataContext = createContext()

// Crear el proveedor
const GlobalDataProvider = ({ children }) => {
  const [isTemplateLoading, setIsTemplateLoading] = useState(true)
  const [templateData, setTemplateData] = useState()

  const DEFAULT_TEMP_DATA = {}
  const { d_home_page, d_gallery, d_menu, d_about_us, d_company_info } = DEFAULT_TEMP_DATA

  const DataError = () => {
    return (
      <div style={{ padding: '40px', textAlign: 'center' }}>
        <br />
        <br />
        <h1>Sitio web en mantenimiento. Estaremos disponibles en un par de horas...</h1>
        <br />
        <a href="https://lechonalechoncitos.com/blog/">
          Mientras tanto, puedes visitar nuestro Blog
        </a>
        <br />
        <br />
      </div>
    )
  }

  const setInitialTemplateData = (template_data) => {
    const { home_page, gallery, menu, about_us, company_info, theme } = template_data

    const finalData = {
      home_page: home_page ?? d_home_page,
      gallery: gallery ?? d_gallery,
      menu: menu ?? d_menu,
      about_us: about_us ?? d_about_us,
      company_info: company_info ?? d_company_info,
      theme
    }
    setTemplateData(finalData)
    // console.log(finalData)

    // .btn-order-now { @extend %btn-order-now; }
    // .default-btn { @extend %default-btn; }
    // .main-color-1 { color: $main-color-1 }
    // .main-color-2 { color: $main-color-2 }
  }

  useEffect(() => {
    if (!templateData) return
    // console.log('tpdata ', templateData)
    const theme = templateData?.theme
    const { color_1 = '#FBB03B', color_2 = '#830000', color_3 = '#00426C' } = theme

    const templateStyles = getTemplateStyles({
      mainColor1: color_1,
      mainColor2: color_2,
      mainColor3: color_3
    })
    const styleElement = document.createElement('style')
    styleElement.textContent = templateStyles
    document.head.appendChild(styleElement)

    return () => {
      document.head.removeChild(styleElement)
    }
  }, [templateData])

  useEffect(() => {
    setIsTemplateLoading(true)
    fetchConfiguracion()
      .then((res) => {
        if (!res.length > 0 || !res[0]['acf']) {
          setIsTemplateLoading(false)
          console.error('Error al obtener los datos ACF')
          return
        }
        setInitialTemplateData(res[0]['acf'])
        // console.log('Datos de configuración:', res)
        setIsTemplateLoading(false)
      })
      .catch((error) => {
        console.error('Error al obtener la configuración:', error)
      })
  }, [])

  return (
    <GlobalDataContext.Provider value={{ TEMPLATE_CONFIG: { templateData, isTemplateLoading } }}>
      {(!templateData?.home_page ||
        !templateData?.gallery ||
        !templateData?.menu ||
        !templateData?.about_us ||
        !templateData?.company_info) &&
      !isTemplateLoading ? (
        <DataError />
      ) : (
        children
      )}
    </GlobalDataContext.Provider>
  )
}

export { GlobalDataContext, GlobalDataProvider }
